import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import RootReducers from './Rootreducers'; // Ensure this path and name are correct

const store = configureStore({
  reducer: RootReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;