import React, { createContext, useState, useContext, useEffect } from 'react';
import { UserContext } from './UserContext';
import makeAuthenticatedRequest from '../api';

const ProjectsContext = createContext();

export const ProjectsProvider = ({ children }) => {
  const { user, refreshToken } = useContext(UserContext);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      if (user) {
        try {
          const response = await makeAuthenticatedRequest(`clients/${user.client.id}/projects`, refreshToken, {
            method: 'get',
            data: null,
            headers: {}
          });
          
          if (Array.isArray(response.data.projects)) {
            setProjects(response.data.projects);
          } else {
            setProjects([]);
          }
        } catch (error) {
          console.error('Error fetching projects:', error);
        }
      }
    };

    fetchProjects();
  }, [user]);

  const createProject = async (projectData) => {
    try {
      const response = await makeAuthenticatedRequest(`clients/${user.client.id}/projects`, refreshToken, {
        method: 'post',
        data: projectData,
        headers: {}
      });
      setProjects([...projects, response.data.project]);
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  const updateProject = async (projectId, updatedData) => {
    try {
      const response = await makeAuthenticatedRequest(`clients/${user.client.id}/projects/${projectId}`, refreshToken, {
        method: 'put',
        data:  updatedData, 
        headers: {}
      });
      setProjects(projects.map(project => project.id === projectId ? response.data.project : project));
    } catch (error) {
      console.error('Error updating project:', error);
    }
  };

  const deleteProject = async (projectId) => {
    try {
      await makeAuthenticatedRequest(`clients/${user.client.id}/projects/${projectId}`, refreshToken, {
        method: 'delete',
        data: null,
        headers: {}
      });
      setProjects(projects.filter(project => project.id !== projectId));
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  //MAKE ADMIN FUNCTIONS
  // const createProject = async (projectData) => {
  //   try {
  //     const response = await makeAuthenticatedRequest(`projects`, refreshToken, {
  //       method: 'post',
  //       data: projectData,
  //       headers: {}
  //     });
  //     setProjects([...projects, response.data.project]);
  //   } catch (error) {
  //     console.error('Error creating project:', error);
  //   }
  // };

  // const updateProject = async (projectId, updatedData) => {
  //   try {
  //     const response = await makeAuthenticatedRequest(`projects/${projectId}`, refreshToken, {
  //       method: 'put',
  //       data:  updatedData, 
  //       headers: {}
  //     });
  //     setProjects(projects.map(project => project.id === projectId ? response.data.project : project));
  //   } catch (error) {
  //     console.error('Error updating project:', error);
  //   }
  // };

  // const deleteProject = async (projectId) => {
  //   try {
  //     await makeAuthenticatedRequest(`projects/${projectId}`, refreshToken, {
  //       method: 'delete',
  //       data: null,
  //       headers: {}
  //     });
  //     setProjects(projects.filter(project => project.id !== projectId));
  //   } catch (error) {
  //     console.error('Error deleting project:', error);
  //   }
  // };

  return (
    <ProjectsContext.Provider value={{ projects, createProject, updateProject, deleteProject }}>
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjects = () => useContext(ProjectsContext);
