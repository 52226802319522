import axios from 'axios';
import { backend_url as backendUrl } from './config/variables';

// Generic function to make an authenticated request
const makeAuthenticatedRequest = async (endpoint, refreshToken, options = {}) => {
  const { method = 'get', data = null, headers = {}, responseType = null } = options;

  try {
    const response = await axios({
      method,
      url: `${backendUrl}/${endpoint}`,
      data,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        ...headers,
      },
      responseType, // Include responseType here
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Token might be expired, try refreshing it
      await refreshToken();
      // Retry the request
      const retryResponse = await axios({
        method,
        url: `${backendUrl}/${endpoint}`,
        data,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          ...headers,
        },
        responseType, // Include responseType here as well
      });
      return retryResponse;
    } else {
      console.error('Request failed:', error);
      throw error;
    }
  }
};

export default makeAuthenticatedRequest;