import React, { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import { isAuthenticated } from '../Protected';
import {
  clientColumns, clientFields,
  invoicesColumns, invoicesFields,
  plansColumns, plansFields,
  usersColumns, usersFields,
  projectsColumns, projectsFields
} from '../data/tableData';


/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() => import('../layouts/full-layout/FullLayout'))
);
const BlankLayout = Loadable(
  lazy(() => import('../layouts/blank-layout/BlankLayout'))
);
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Register = Loadable(
  lazy(() => import('../views/authentication/Register'))
);
const ResetPassword = Loadable(
  lazy(() => import('../views/authentication/ResetPassword'))
);
const NewPassword = Loadable(
  lazy(() => import('../views/authentication/newPassword2'))
);
const MailSent = Loadable(
  lazy(() => import('../views/authentication/MailSent'))
);

const QuickDownload = Loadable(
  lazy(() => import('../views/downloadables/QuickDownload'))
);
const QuickReportView = Loadable(
  lazy(() => import('../views/reports/QuickDownloadReport'))
);
const PasswordUpdated = Loadable(
  lazy(() => import('../views/authentication/PasswordUpdated'))
);
const RequestPasswordReset = Loadable(
  lazy(() => import('../views/authentication/RequestPasswordReset'))
);

/* ****Pages***** */
const Dashboard = Loadable(
  lazy(() => import('../pages/Dashboard/Dashboard'))
);
const ConfigProjects = Loadable(
  lazy(() => import('../components/projects/ConfigProjects'))
);
const Projects = Loadable(
  lazy(() => import('../components/projects/Projects'))
);
const ProjectRowWrapper = Loadable(
  lazy(() => import('../components/projects/ProjectRowWrapper'))
);
const ConfigUsers = Loadable(
  lazy(() => import('../pages/users/ConfigUsers'))
);
const ProjectPage = Loadable(
  lazy(() => import('../pages/ProjectPage'))
);
const Dashboard2 = Loadable(
  lazy(() => import('../views/dashboards/Dashboard2'))
);
const Dashboard3 = Loadable(
  lazy(() => import('../views/dashboards/Dashboard3'))
);
const FermDashboard = Loadable(
  lazy(() => import('../pages/Dashboard/Dashboard'))
);
const TanksPage = Loadable(
  lazy(() => import('../pages/Tanks/TankPage'))
);
const BatchsPage = Loadable(
  lazy(() => import('../pages/Batchs/BatchPage'))
);
const RecordTypePage = Loadable(
  lazy(() => import('../pages/Records/RecordTypePage'))
);



// const IncomeReports = Loadable(lazy(() => import("../views/reports/IncomeReports")));
const IncomeReportDetail = Loadable(
  lazy(() => import('../views/reports/IncomeReportDetails'))
);
const TrafficReports = Loadable(
  lazy(() => import('../views/reports/TrafficReports'))
);
const Reports = Loadable(lazy(() => import('../views/reports/ReportList')));
const ReportView = Loadable(lazy(() => import('../views/reports/ReportView')));
const DownloadReport = Loadable(lazy (() => import('../views/reports/DownloadReport')));
const LogsView = Loadable(lazy(() => import('../views/logs/LogsView')));
const RiskEvaluation = Loadable(lazy(() => import('../views/reports/RiskEvaluation')));
const Status = Loadable(lazy(() => import('../views/status/Status')));

/* **** Services => Factoring ***** */
const FactoringCompanies = Loadable(lazy(() => import('../views/factoring/CompaniesList')));
const FactoringCompanyView = Loadable(lazy(() => import('../views/factoring/CompanyView')));
/* **** Services => Widget ***** */
const WidgetTable = Loadable(
  lazy(() => import('../views/floid_widget/WidgetTable'))
);
const WidgetEdit = Loadable(
  lazy(() => import('../views/floid_widget/WidgetEdit'))
);

/* ****Services => Downloadables***** */
const Downloadables = Loadable(lazy(() => import('../views/downloadables/DownloadableView')));

/* ****Tables***** */
const BasicTable = Loadable(lazy(() => import('../views/tables/BasicTable')));
const PaginationTable = Loadable(
  lazy(() => import('../views/tables/PaginationTable'))
);
const EnhancedTable = Loadable(
  lazy(() => import('../views/tables/EnhancedTable'))
);
const CollapsibleTable = Loadable(
  lazy(() => import('../views/tables/CollapsibleTable'))
);
const FixedHeaderTable = Loadable(
  lazy(() => import('../views/tables/FixedHeaderTable'))
);
const TokensTable = Loadable(lazy(() => import('../views/tokens/Tokens')));
const TokenView = Loadable(lazy(() => import('../views/tokens/TokenView')));
const QuotesTable = Loadable(
  lazy(() => import('../views/billing/QuotesTable'))
);
const ContractsTable = Loadable(
  lazy(() => import('../views/billing/ContractsTable'))
);
const InvoicesTable = Loadable(
  lazy(() => import('../views/billing/InvoicesTable'))
);
const InvoiceView = Loadable(
  lazy(() => import('../views/billing/InvoiceView'))
);
const QuoteView = Loadable(lazy(() => import('../views/billing/QuoteView')));
const ContractView = Loadable(
  lazy(() => import('../views/billing/ContractView'))
);
const PaymentsView = Loadable(
  lazy(() => import('../views/payments/PaymentsListView'))
);
const PaymentsSettings = Loadable(
  lazy(() => import('../views/payments/PaymentWidgetList'))
);
const PaymentWidgetDetails = Loadable(
  lazy(() => import('../views/payments/WidgetDetails'))
);
// form elements
const ExAutoComplete = Loadable(
  lazy(() => import('../views/form-elements/ExAutoComplete'))
);
const ExButton = Loadable(
  lazy(() => import('../views/form-elements/ExButton'))
);
const ExCheckbox = Loadable(
  lazy(() => import('../views/form-elements/ExCheckbox'))
);
const ExDateTime = Loadable(
  lazy(() => import('../views/form-elements/ExDateTime'))
);
const ExRadio = Loadable(lazy(() => import('../views/form-elements/ExRadio')));
const ExSlider = Loadable(
  lazy(() => import('../views/form-elements/ExSlider'))
);
const ExSwitch = Loadable(
  lazy(() => import('../views/form-elements/ExSwitch'))
);
const FormWizard = Loadable(
  lazy(() => import('../views/form-layouts/FormWizard'))
);
// form layouts
const FormLayouts = Loadable(
  lazy(() => import('../views/form-layouts/FormLayouts'))
);
const FormCustom = Loadable(
  lazy(() => import('../views/form-layouts/FormCustom'))
);

// widgets
const WidgetFeed = Loadable(
  lazy(() => import('../views/widgets/widget-feed/WidgetFeed'))
);
const WidgetApps = Loadable(
  lazy(() => import('../views/widgets/widget-apps/WidgetApps'))
);

// userprofile
const UserProfile = Loadable(
  lazy(() => import('../views/user-profile/UserProfile'))
);
const UserNewPassword = Loadable(
  lazy(() => import('../views/user-profile/UserNewPassword'))
);
const CompanyProfile = Loadable(
  lazy(() => import('../views/user-profile/CompanyProfile'))
);
const CompanyUser = Loadable(
  lazy(() => import('../views/user-profile/CompanyUserUpdate'))
);
const CompanyUserCreate = Loadable(
  lazy(() => import('../views/user-profile/CompanyUserCreate'))
);

// chart
const LineChart = Loadable(lazy(() => import('../views/charts/LineChart')));
const GredientChart = Loadable(
  lazy(() => import('../views/charts/GredientChart'))
);
const DoughnutChart = Loadable(
  lazy(() => import('../views/charts/DoughnutChart'))
);
const AreaChart = Loadable(lazy(() => import('../views/charts/AreaChart')));
const ColumnChart = Loadable(lazy(() => import('../views/charts/ColumnChart')));
const CandlestickChart = Loadable(
  lazy(() => import('../views/charts/CandlestickChart'))
);
const RadialbarChart = Loadable(
  lazy(() => import('../views/charts/RadialbarChart'))
);

// icons
const ReactIcons = Loadable(lazy(() => import('../views/icons/ReactIcons')));

// Alert
const ExAlert = Loadable(lazy(() => import('../views/alert/ExAlert')));

const EditableTable = Loadable(lazy(() => import('../components/EditableTable')));

/* ****Routes***** */

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <ProjectPage /> },
      { path: '/dashboard2', exact: true, element: <Dashboard2 /> },
      { path: '/dashboard3', exact: true, element: <Dashboard3 /> },
      { path: '/ferm-dashboard', exact: true, element: <FermDashboard /> },
      { path: '/tanks', exact: true, element: <TanksPage /> },
      { path: '/batchs', exact: true, element: <BatchsPage /> },
      { path: '/recordtypes', exact: true, element: <RecordTypePage /> },
      { path: '/tables/basic-table', element: <BasicTable /> },
      { path: '/tables/pagination-table', element: <PaginationTable /> },
      { path: '/tables/enhanced-table', element: <EnhancedTable /> },
      { path: '/tables/collapsible-table', element: <CollapsibleTable /> },
      { path: '/tables/fixed-header-table', element: <FixedHeaderTable /> },
      { path: '/form-layouts/form-layouts', element: <FormLayouts /> },
      { path: '/form-elements/autocomplete', element: <ExAutoComplete /> },
      { path: '/form-elements/button', element: <ExButton /> },
      { path: '/form-elements/checkbox', element: <ExCheckbox /> },
      { path: '/form-elements/date-time', element: <ExDateTime /> },
      { path: '/form-elements/radio', element: <ExRadio /> },
      { path: '/form-elements/slider', element: <ExSlider /> },
      { path: '/form-elements/switch', element: <ExSwitch /> },
      { path: '/form-layouts/form-wizard', element: <FormWizard /> },
      { path: '/widgets/widget-feed', element: <WidgetFeed /> },
      { path: '/widgets/widget-apps', element: <WidgetApps /> },
      { path: '/user-profile', element: <UserProfile /> },
      { path: '/charts/line-chart', element: <LineChart /> },
      { path: '/charts/gredient-chart', element: <GredientChart /> },
      { path: '/charts/doughnut-pie-chart', element: <DoughnutChart /> },
      { path: '/charts/area-chart', element: <AreaChart /> },
      { path: '/charts/column-chart', element: <ColumnChart /> },
      { path: '/charts/candlestick-chart', element: <CandlestickChart /> },
      { path: '/charts/radialbar-chart', element: <RadialbarChart /> },
      { path: '/react-icons', element: <ReactIcons /> },
      { path: '/form-layouts/form-custom', element: <FormCustom /> },
      // { path: '/quill-editor', element: <QuillEditor /> },
      { path: '/alert', element: <ExAlert /> },
      { path: '/widgets', element: <WidgetTable /> },
      { path: '/widgets/:id', element: <WidgetEdit /> },
      { path: '/tokens', element: <TokensTable /> },
      { path: '/tokens/:id', element: <TokenView /> },
      // { path: '/reports/income_reports', exact: true, element: <IncomeReports /> },
      { path: '/reports/income_report/:id', element: <IncomeReportDetail /> },
      { path: '/reports/traffic_reports', exact: true, element: <TrafficReports />, },
      { path: '/reports/risk', exact: true, element: <RiskEvaluation /> },
      { path: '/reports/:type', exact: true, element: <Reports /> },
      { path: '/report/:caseid', element: <ReportView /> },
      { path: '/report/download/:caseid', element: <DownloadReport /> },
      { path: '/logs', element: <LogsView />},
      { path: '/billing/quotes', element: <QuotesTable /> },
      { path: '/billing/quote/:id', element: <QuoteView /> },
      { path: '/billing/invoices', element: <InvoicesTable /> },
      { path: '/billing/invoice/:id', element: <InvoiceView /> },
      { path: '/billing/contracts', element: <ContractsTable /> },
      { path: '/billing/contract/:id', element: <ContractView /> },
      { path: '/factoring', exact: true, element: <FactoringCompanies />, },
      { path: '/factoring/:id', exact: true, element: <FactoringCompanyView />, },
      { path: '/settings/company', element: <CompanyProfile /> },
      { path: '/settings/company/user', element: <CompanyUser /> },
      { path: '/settings/company/user/create', element: <CompanyUserCreate /> },
      { path: '/settings/user', element: <UserProfile /> },
      { path: '/settings/user/new-password', element: <UserNewPassword /> },
      { path: '/downloadables', exact: true ,element: <Downloadables /> },
      { path: '/payments', exact: true ,element: <PaymentsView /> },
      { path: '/payments/widgets', exact: true ,element: <PaymentsSettings /> },
      { path: '/payments/widgets/:id', exact: true ,element: <PaymentWidgetDetails /> },
      { path: '/status', exact: true ,element: <Status /> },
      { path: '/admin/clients', exact: true ,element: <EditableTable endpoint="clients" columns={clientColumns} fields={clientFields} /> },
      { path: '/admin/users', exact: true ,element: <EditableTable endpoint="users" columns={usersColumns} fields={usersFields} /> },
      { path: '/admin/invoices', exact: true ,element: <EditableTable endpoint="invoices" columns={invoicesColumns} fields={invoicesFields} /> },
      { path: '/admin/plans', exact: true ,element: <EditableTable endpoint="plans" columns={plansColumns} fields={plansFields} /> },
      { path: '/admin/projects', exact: true ,element: <EditableTable endpoint="projects" columns={projectsColumns} fields={projectsFields} /> },
      { path: '/config/users', exact:true, element: <ConfigUsers />},
      { path: '/config/projects', exact: true, element: <ConfigProjects /> },
      { path: '/projects', exact: true, element: <ProjectPage /> },
      { path: '/projects/:id', exact: true, element: <ProjectRowWrapper /> },
      { path: '*', element: <Navigate to='/auth/404' /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '/auth/404', element: <Error /> },
      { path: '/auth/login', element: <Login /> },
      { path: '/auth/register', element: <Register /> },
      // { path: '/auth/reset-password', element: <ResetPassword /> },
      { path: '/auth/new-password', element: <NewPassword /> },
      // { path: '/auth/new-password/:token', element: <NewPassword />},
      { path: '/auth/request-password-reset', element: <RequestPasswordReset /> },
      { path: '/auth/password-updated', element: <PasswordUpdated /> },
      { path: '/auth/mail-sent', element: <MailSent /> },
      { path: '/auth/quickdownload', element: <Register /> },
      { path: '*', element: <Navigate to='/auth/404' /> },
    ],
  },
  {
    // add single rout that does not need to be authenticated nor have a layout
    path: '/quickdownload',
    element: <BlankLayout />,
    children: [
      { path: '/quickdownload', element: <QuickDownload /> },
      { path: '/quickdownload/view', element: <QuickReportView /> },
      { path: '*', element: <Navigate to='/auth/404' /> },
    ],
  },
  {
    path: '/download',
    element: <BlankLayout />,
    children: [
      { path: '/download/report/:caseid', element: <ReportView /> },
      { path: '/download/billing/invoice/:id', element: <InvoiceView /> },
      { path: '/download/billing/quote/:id', element: <QuoteView /> },
      { path: '/download/billing/contract/:id', element: <ContractView /> },
    ],

  }
];

export default Router;
