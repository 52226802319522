import React from 'react';
import { Navigate, useLocation } from 'react-router';

const Protected = ({ children }) => {
  const session = localStorage.getItem('successStatus');
  // Redirect to login if not authenticated
  if (!(session === 'true')) {
    return <Navigate to='/auth/login' replace />;
  }

  return children;
};

export default Protected;