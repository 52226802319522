// src/App.js
import React, { useContext } from 'react';
//import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CssBaseline, ThemeProvider } from '@mui/material';
import RTL from './layouts/full-layout/customizer/RTL';
import ThemeSettings from './layouts/full-layout/customizer/ThemeSettings';
import 'react-perfect-scrollbar/dist/css/styles.css';

import Router from './routes/Router';
import { UserProvider } from './context/UserContext';
import { ProjectsProvider } from './context/ProjectsContext';

function App() {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state) => state.CustomizerReducer);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <UserProvider>
          <ProjectsProvider>
            <CssBaseline />
            {routing}
          </ProjectsProvider>
        </UserProvider>
      </RTL>
    </ThemeProvider>
  );
}

export default App;
