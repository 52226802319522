// create context and provider
import React, { createContext, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { backend_url as backendUrl } from '../config/variables';
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setUserData();
  }, []);
  
  const publicRoutes = [
    '/auth/login',
    '/auth/register',
    '/auth/request-password-reset',
    '/auth/new-password',
    '/auth/password-updated',
    '/auth/mail-sent',
    '/quickdownload',
    '/quickdownload/view',
    '/auth/404',
    '/download/report/:caseid',
    '/download/billing/invoice/:id',
    '/download/billing/quote/:id',
    '/download/billing/contract/:id',
  ];

  function setUserData() {
    const token = localStorage.getItem('token');
    const success = localStorage.getItem('successStatus');

    if (success === 'true' && token) {
      const info = jwtDecode(token);

      if (info?.sub) {
        const user = info.sub;
        setUser(user);
      }
    } else {
      const url = new URL(window.location.href);
      const pathname = url.pathname;
      const searchParams = url.searchParams;
      if (pathname === '/auth/new-password' && searchParams.has('token')) {
        // Allow /auth/new-password with a token query parameter
        return;
      }

      if (!publicRoutes.includes(pathname) ) {
        localStorage.clear();
        navigate('/auth/login');
        window.location.reload();
      }
    }
  }

  const refreshToken = async () => {
    try {
      const response = await axios.post(`${backendUrl}/refresh`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('refreshToken')}`
        }
      });
      localStorage.setItem('token', response.data.access_token);
    } catch (error) {
      console.error('Error refreshing token:', error);
      localStorage.clear();
      navigate('/auth/login');
    }
  };


  return (
    <UserContext.Provider value={{ user, setUserData, setUser, refreshToken }}>
      {props.children}
    </UserContext.Provider>
  );
};
