const clientColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'rut', headerName: 'RUT', width: 150 },
    { field: 'address', headerName: 'Address', width: 200 },
    { field: 'logo', headerName: 'Logo', width: 150 },
    // Add more columns as needed
  ];

  const clientFields = [
    { name: 'name', label: 'Name' },
    { name: 'logo', label: 'Logo' },
    { name: 'rut', label: 'RUT' },
    { name: 'address', label: 'Address' },
  ];

  const invoicesColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'client_id', headerName: 'Client ID', width: 150 },
    { field: 'total_amount', headerName: 'Total Amount', width: 150 },
    { field: 'plan_id', headerName: 'Plan ID', width: 150 },
    // Add more columns as needed
  ];
  
  const invoicesFields = [
    { name: 'date', label: 'Date', type: 'date' },
    { name: 'client_id', label: 'Client ID', type: 'number' },
    { name: 'total_amount', label: 'Total Amount', type: 'number' },
    { name: 'plan_id', label: 'Plan ID', type: 'number' },
    // Add more fields as needed
  ];
  
  const plansColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'monthly_requests', headerName: 'Monthly Requests', width: 150 },
    { field: 'charge_uf', headerName: 'Charge UF', width: 150 },
    { field: 'extra_request_fee', headerName: 'Extra Request Fee', width: 150 },
    // Add more columns as needed
  ];
  
  const plansFields = [
    { name: 'name', label: 'Name', type: 'text' },
    { name: 'monthly_requests', label: 'Monthly Requests', type: 'number' },
    { name: 'charge_uf', label: 'Charge UF', type: 'number' },
    { name: 'extra_request_fee', label: 'Extra Request Fee', type: 'number' },
    // Add more fields as needed
  ];
  
  const usersColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'first_name', headerName: 'Nombre', width: 200 },
    { field: 'last_name', headerName: 'Apellido', width: 200 },
    { field: 'client_id', headerName: 'Client ID', width: 150 },
    { field: 'permission_id', headerName: 'Permission ID', width: 150 },
  ];
  
  const usersFields = [
    { name: 'email', label: 'Email', type: 'email' },
    { name: 'first_name', label: 'Nombre', type: 'text' },
    { name: 'last_name', label: 'Apellido', type: 'text' },
    { name: 'client_id', label: 'Client ID', type: 'number' },
    { name: 'permission_id', label: 'Permission ID', type: 'number' },
  ];
  
  const projectsColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'client_id', headerName: 'client_id', width: 150 }
    // Add more columns as needed
  ];
  
  const projectsFields = [
    { name: 'id', label: 'ID' },
    { name: 'name', label: 'Name' },
    { name: 'client_id', label: 'Client ID' }
  ];


  export {
    clientColumns, clientFields,
    invoicesColumns, invoicesFields,
    plansColumns, plansFields,
    usersColumns, usersFields,
    projectsColumns, projectsFields
  }; 